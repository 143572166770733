import React, {useEffect} from "react";
import FormField from "../FormComponents/FormField";
import {NextButton} from "../FormComponents/NextButton";
import {GAEvent, GAModalView} from "../../../../services/google-analytics.service";

const Names = ({state, action, register, errors, navigation, getValues, trigger}) => {
    const {next} = navigation;

    useEffect(() => {
        GAModalView("BookingForm-Names");
    }, []);

    const checkErrors = async () => {
        const result = await trigger(['firstName', 'lastName', 'nickName']);
        if (result) {
            GAEvent('Booking', 'Clicked Next On Names', "Navigation");
            const values = getValues();
            action(values)
            next()
        }
    };

    return (
        <div className="booking-form">
            <div className="page-title">
                <h2 className="title">Names</h2>
            </div>
            <FormField
                label="First Name"
                name="firstName"
                reference={register({required: "Required"})}
                errors={errors.firstName}
                defaultValue={state.data.firstName}
            />
            <FormField
                label="Last Name"
                name="lastName"
                reference={register({required: "Required"})}
                errors={errors.lastName}
                defaultValue={state.data.lastName}
            />
            <FormField
                label="Preferred Name"
                name="nickName"
                reference={register}
                errors={errors.nickName}
                defaultValue={state.data.nickName}
            />
            <div className="col-md-12">
                <NextButton onNext={checkErrors}/>
            </div>
        </div>
    );
};

export default Names;
