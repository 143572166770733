import React, {useState} from "react";
import {businessDetailsService} from "../../services/business-details.service";
import {useForm} from 'react-hook-form';
import {Alert} from "../../shared/Alert";
import {StatusCodes, submitContactForm} from "../../services/contact.service";
import {PreLoader} from "../../shared/Preloader";
import {GAEvent} from "../../services/google-analytics.service";

export function ContactSection() {
    const {handleSubmit, register, reset, errors} = useForm();
    const [alert, showAlert] = useState(null);
    const [loading, showLoading] = useState(null);

    function handleResponse(response) {
        response.then(response => {
            if (StatusCodes.SUCCESS === response.status) {
                GAEvent('Contact', 'Contact Form Submission Success', response.message);
                showAlert(() => {
                    return (
                        <Alert status={StatusCodes.SUCCESS} message={response.message}/>
                    );
                });
                showLoading(null)
                reset();
            } else {
                GAEvent('Contact', 'Contact Form Submission Error', response.message);
                showAlert(() => {
                    return (
                        <Alert status={StatusCodes.ERROR} message={response.message}/>
                    );
                });
                showLoading(null)
            }
        }).catch(error => {
            GAEvent('Booking', 'Booking Form Submission Error', error.message);
            showAlert(() => {
                return (
                    <Alert status={StatusCodes.ERROR} message={error.message}/>
                );
            });
            showLoading(null)
        });
    }

    const submit = (data) => {
        showLoading(<PreLoader/>);
        GAEvent('Contact', 'Contact Form Submission', "Submit");
        const response = submitContactForm(data);
        handleResponse(response);
    }


    return (
        <section className="contact col-md-12">
            <div className="col-md-3 contact-info">
                <div className="page-title bottom_30">
                    <span>GET IN TOUCH</span>
                    <h2 className="title">Communicate with me</h2>
                </div>
                <ul>
                    <li>
                        {businessDetailsService.contactDetails.address}
                    </li>
                    <br/>
                    <li>{businessDetailsService.contactDetails.email}
                        <br/>
                        {businessDetailsService.contactDetails.phone}
                    </li>
                </ul>
                <div className="social top_45">

                    <a href={businessDetailsService.contactDetails.emailLink} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Email Button', "Email")
                       }}
                    >
                        <i className="fa fa-envelope-o" aria-hidden="true">
                        </i>
                    </a>
                    <a href={businessDetailsService.socialMedia.instagram} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Instagram Button', "Instagram")
                       }}
                    >
                        <i className="fa fa-instagram" aria-hidden="true">
                        </i>
                    </a>
                    <a href={businessDetailsService.socialMedia.facebook} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Facebook Button', "Facebook")
                       }}
                    >
                        <i className="fa fa-facebook" aria-hidden="true">
                        </i>
                    </a>
                    <a href={businessDetailsService.socialMedia.twitter} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Twitter Button', "Twitter")
                       }}
                    >
                        <i className="fa fa-twitter" aria-hidden="true">
                        </i>
                    </a>
                </div>
            </div>

            <div className="col-md-7 col-md-offset-2 form top_90 bottom_90">
                <div className="page-title sub bottom_30 col-md-12">
                    <span>CONTACT US</span>
                    <h5 className="title">leave us a message</h5>
                </div>
                <form onSubmit={handleSubmit(submit)}>
                    <div className="col-md-12 contact-form">
                        <div className="row">
                            <div className="col-md-6">
                                <input id="name" name="name" className="form-inp requie"
                                       ref={register({required: "Required"})} type="text"
                                       placeholder="Name"/>
                                <span style={{color: 'red'}}>{errors.name && errors.name.message}</span>
                            </div>
                            <div className="col-md-6">
                                <input id="email" name="email" className="form-inp requie" ref={register({
                                    required: "Required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }
                                })} type="text"
                                       placeholder="Email"/>
                                <span style={{color: 'red'}}>{errors.email && errors.email.message}</span>
                            </div>
                            <div className="col-md-12">
                            <textarea name="message" id="message" className="form-inp" ref={register}
                                      placeholder="How can I help you?" rows="8">
                            </textarea>
                                {loading}
                                {alert}
                                <button id="submit" className="sitebtn top_45 pull-right" type="submit">Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

