import React from "react";

export class ServicesSection extends React.Component {
    render() {
        return (
            <section className="col-md-12 top_30 bottom_15" style={{height: '200px'}}>
                <div className="owl-carousel work-areas" data-pagination="true" data-autoplay="3000"
                     data-items-desktop="3"
                     data-items-desktop-small="3" data-items-tablet="2" data-items-tablet-small="1">
                    <div className="area col-md-12 item">
                        <div className="icon">
                            <i className="flaticon-html"></i>
                        </div>
                        <div className="text">
                            <h6>Photography</h6>
                            <p>Invite us capture your life's greatest moments.
                            </p>
                        </div>
                    </div>
                    <div className="area col-md-12 item">
                        <div className="icon">
                            <i className="flaticon-attach"></i>
                        </div>
                        <div className="text">
                            <h6>Videography</h6>
                            <p>We shoot all kinds of videos from music videos to ads and all other times of motion picture.</p>
                        </div>
                    </div>
                    <div className="area col-md-12 item">
                        <div className="icon">
                            <i className="flaticon-vector"></i>
                        </div>
                        <div className="text">
                            <h6>Graphic Design</h6>
                            <p>We design mind blowing graphics for various use cases. </p>
                        </div>
                    </div>
                    <div className="area col-md-12 item">
                        <div className="icon">
                            <i className="flaticon-html"></i>
                        </div>
                        <div className="text">
                            <h6>Photo Editing</h6>
                            <p>We edit your raw photos and make them ready for the perfect display
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
