import React, {useEffect} from 'react';
import {Main} from "../../shared/Main";
import {Hero} from "../../shared/Hero";
import {ContactSection} from "./ContactSection";
import $ from 'jquery';
import {GAPageView} from "../../services/google-analytics.service";
import {usePagesQuery} from "../../generated/graphql";

export default function Contact() {
    const [{data, fetching}] = usePagesQuery({
        variables: {"Page": "Contact"}
    });

    const body = document.getElementById('body');
    $(body).addClass('blue');
    useEffect(() => {
        GAPageView("Contact");
    }, []);

    if(!fetching) console.log('Contact Page', data);
    return (
        <>
            <Hero imageUrl={!fetching ? data.pages[0].backgroundImage.url : ''} page={'contact'} title={!fetching ? data.pages[0].displayName : ''}
                  subtitle={!fetching ? data.pages[0].description : ''}/>
            <Main sections={
                <ContactSection/>
            }
            />
        </>
    );
}
