import React from "react";


const Selector = ({items, label, errors, reference, ...others}) => (
    <>
        <div className="col-md-6">
            <select ref={reference} {...others} className="form-inp requie">
                <option key={0} value={undefined}>Select..</option>
                {items.map(([id, name]) => (
                    <option key={id.toString()} value={name}>{name}</option>
                ))}
            </select>
            <span style={{color: 'red'}}>{errors && errors.message}</span>
        </div>
    </>
);

export default Selector;
