import React from "react";
import {businessDetailsService} from "../services/business-details.service";
import {GAEvent} from "../services/google-analytics.service";
import {PaymentButton} from "./PaymentButton";

export class Footer extends React.Component {
    render() {
        return (
            <footer className="top_120">
                <div className="container">
                    <div className="socials pull-left">
                        <div className="footer-logo pull-left">
                            <img style={{maxWidth: '50px', maxHeight: '50px', marginBottom: '30px'}} src="img/logo.png" alt=""/>
                        </div>
                        <a href={businessDetailsService.contactDetails.emailLink} target="_blank" rel="noopener noreferrer"
                           onClick={() => {
                               GAEvent('Social Media', 'Click Email Button', "Email")
                           }}>Email</a>
                        <a href={businessDetailsService.socialMedia.facebook} target="_blank" rel="noopener noreferrer"
                           onClick={() => {
                               GAEvent('Social Media', 'Click Facebook Button', "Facebook")
                           }}
                        >Facebook</a>
                        <a href={businessDetailsService.socialMedia.twitter} target="_blank" rel="noopener noreferrer"
                           onClick={() => {
                               GAEvent('Social Media', 'Click Twitter Button', "Twitter")
                           }}
                        >Twitter</a>
                        <a href={businessDetailsService.socialMedia.instagram} target="_blank" rel="noopener noreferrer"
                           onClick={() => {
                               GAEvent('Social Media', 'Click Instagram Button', "Instagram")
                           }}
                        >Instagram</a>
                    </div>
                    <div className="copyright pull-right bottom_30">
                        Copyright 2020 All right reserved. Development by <span>Yelah</span>
                        <PaymentButton className={"top_45"}
                                       title={"Pay Deposit"}
                                       buttonStyling={{width: "100%"}}
                        />
                    </div>
                </div>
            </footer>);
    }
}
