import React from "react";
import * as PropTypes from "prop-types";
import {GAEvent} from "../../../services/google-analytics.service";

export class PortfolioItem extends React.Component {
    render() {
        let {name, category, image, description, id, videoLink} = this.props;
        const imageUrl = image ? image.url : 'img/work-0.jpg';
        const itemLink = videoLink ? videoLink : imageUrl;
        const defaultCategory = videoLink ? 'Videography' : 'Photography';

        return (
            <>
                <a id={id}
                   className={"col-md-6 col-sm-6 col-xs-12 single_item " + (category ? category : defaultCategory)}
                   data-fancybox="gallery"
                   href={itemLink} onClick={() =>
                    GAEvent(category, category + ' View ' + (itemLink), name ? name : 'blank_image')
                }>
                    <figure>
                        <img src={imageUrl} alt={name}/>
                        <figcaption>
                            <h3>{name ? name : ''}</h3>
                            <p>{description ? description : ''}</p>
                        </figcaption>
                    </figure>
                </a>

            </>
        );
    }
}

PortfolioItem.propTypes = {
    name: PropTypes.any,
    category: PropTypes.any,
    image: PropTypes.any,
    description: PropTypes.any,
    videoLink: PropTypes.any,
    id: PropTypes.any
}
