import React from "react";
import {businessDetailsService} from "../services/business-details.service";
import {GAEvent} from "../services/google-analytics.service";

export class Navigation extends React.Component {
    render() {
        return (
            <>
                <header>
                    <div className="logo pull-left">
                        <img style={{maxWidth: '50px', maxHeight: '50px'}} src="img/logo.png" alt=""/>
                    </div>
                    <div className="hamburger pull-right">
                        <div id="nav-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </header>

                <div className="nav">
                    <ul className="menu">
                        <li className="hassub"><a href="/">Portfolio</a>
                        </li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/booking">Booking</a></li>
                        <li className="social">
                            <div className="social">
                                <a href={businessDetailsService.contactDetails.emailLink} target="_blank"
                                   rel="noopener noreferrer"
                                   onClick={() => {
                                       GAEvent('Social Media', 'Click Email Button', "Email")
                                   }}
                                >
                                    <i className="fa fa-envelope-o" aria-hidden="true">
                                    </i>
                                </a>
                                <a href={businessDetailsService.socialMedia.instagram} target="_blank"
                                   rel="noopener noreferrer"
                                   onClick={() => {
                                       GAEvent('Social Media', 'Click Instagram Button', "Instagram")
                                   }}
                                >
                                    <i className="fa fa-instagram" aria-hidden="true">
                                    </i>
                                </a>
                                <a href={businessDetailsService.socialMedia.facebook} target="_blank"
                                   rel="noopener noreferrer"
                                   onClick={() => {
                                       GAEvent('Social Media', 'Click Facebook Button', "Facebook")
                                   }}
                                >
                                    <i className="fa fa-facebook" aria-hidden="true">
                                    </i>
                                </a>
                                <a href={businessDetailsService.socialMedia.twitter} target="_blank"
                                   rel="noopener noreferrer"
                                   onClick={() => {
                                       GAEvent('Social Media', 'Click Twitter Button', "Twitter")
                                   }}
                                >
                                    <i className="fa fa-twitter" aria-hidden="true">
                                    </i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

