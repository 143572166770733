import React from "react";

const TextArea = ({label, children, errors, reference, ...otherProps}) => (
    <div className="col-md-12">
        <textarea ref={reference} name="message" id="message" className="form-inp requie" placeholder={label} rows="8" {...otherProps}>
        </textarea>
        <span style={{color: 'red'}}>{errors && errors.message}</span>
    </div>
);

export default TextArea;

