import React, {useEffect} from "react";
import {GAEvent, GAModalView} from "../../../../services/google-analytics.service";
import {HomeButton} from "../../../../shared/HomeButton";
import {PaymentButton} from "../../../../shared/PaymentButton";
import {businessDetailsService} from "../../../../services/business-details.service";

const Submit = ({navigation, state}) => {

    const {go} = navigation;
    useEffect(() => {
        GAModalView("BookingForm-Submit");
    }, []);

    return (
        <div>
            <div className="page-title">
                <h2 className="title">Thank you for submitting. <br/> We will be in touch to finalize your booking.</h2>
            </div>
            <div style={{float: 'left'}}>
                <button className="stepperbtn stepperbtn-left" onClick={() => {
                    GAEvent('Booking', 'Clicked New Booking', "Navigation");
                    go("names")
                }}>New Booking
                </button>

                <h4 className={"top_45"} style={{color: "#868686", textAlign: "left"}}>
                    <span style={{color: "white"}}>NB:</span> Please note that a
                    <span style={{color: "white"}}> R{businessDetailsService.bookingDetails.fee}</span> deposit will be required to finalise your booking.
                    <br/><br/>
                    You can pay now or once we have contacted you.
                </h4>

                <PaymentButton className={"top_45"}
                               title={"Pay Now"}
                               email={state.data.email}
                               phone={state.data.phone}
                               firstName={state.data.firstName}
                               lastName={state.data.lastName}
                               service={state.data.service}
                               buttonStyling={{width: "100%"}}
                />
                <HomeButton title={"Pay Later"}/>
            </div>
        </div>
    );
};

export default Submit;
