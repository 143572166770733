import React from "react";
import {businessDetailsService} from "../../services/business-details.service";
import {GAEvent} from "../../services/google-analytics.service";
import { Switch, useRouteMatch} from "react-router";
import BookingAction from "./BookingAction";
import Stepper from "./Stepper/Stepper";
import Route from '../../routes/RouteWrapper';

export function BookingSection() {
    let {path} = useRouteMatch();
    return (
        <section className="booking col-md-12">
            <div className="col-md-3 booking-info">
                <div className="page-title bottom_30">
                    <span>Hire Us</span>
                    <h2 className="title">Communicate with me</h2>
                </div>
                <ul>
                    <li>
                        {businessDetailsService.contactDetails.address}
                    </li>
                    <br/>
                    <li>{businessDetailsService.contactDetails.email}
                        <br/>
                        {businessDetailsService.contactDetails.phone}
                    </li>
                </ul>
                <div className="social top_45">
                    <a href={businessDetailsService.contactDetails.emailLink} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Email Button', "Email")
                       }}
                    >
                        <i className="fa fa-envelope-o" aria-hidden="true">
                        </i>
                    </a>
                    <a href={businessDetailsService.socialMedia.instagram} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Instagram Button', "Instagram")
                       }}
                    >
                        <i className="fa fa-instagram" aria-hidden="true">
                        </i>
                    </a>
                    <a href={businessDetailsService.socialMedia.facebook} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Facebook Button', "Facebook")
                       }}
                    >
                        <i className="fa fa-facebook" aria-hidden="true">
                        </i>
                    </a>
                    <a href={businessDetailsService.socialMedia.twitter} target="_blank"
                       rel="noopener noreferrer"
                       onClick={() => {
                           GAEvent('Social Media', 'Click Twitter Button', "Twitter")
                       }}
                    >
                        <i className="fa fa-twitter" aria-hidden="true">
                        </i>
                    </a>
                </div>
            </div>

            <div className="col-md-7 col-md-offset-2 form top_90 bottom_90">
                <div className="page-title sub bottom_30 col-md-12">
                    <span>HIRE US</span>
                    <h5 className="title">thank you for choosing us</h5>
                </div>
                <Switch>
                    <Route exact path={`${path}`} component={Stepper}/>
                    <Route path={`${path}/:paramId`} component={BookingAction}/>
                </Switch>
            </div>
        </section>
    );
}
