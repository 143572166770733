import React, {useEffect} from "react";

import FormField from "../FormComponents/FormField";
import {NextButton} from "../FormComponents/NextButton";
import {PreviousButton} from "../FormComponents/PreviousButton";
import {GAEvent, GAModalView} from "../../../../services/google-analytics.service";

const Contact = ({state, action, register, errors, navigation, getValues, trigger}) => {
    const {previous, next} = navigation

    useEffect(() => {
        GAModalView("BookingForm-Contact");
    }, []);

    const checkErrors = async () => {
        const result = await trigger(['phone', 'email']);
        if (result) {
            if (result) {
                GAEvent('Booking', 'Clicked Next On Contact', "Navigation")
                action(getValues())
                next()
            }
        }
    };


    return (
        <div className="booking-form">
            <div className="page-title">
                <h2 className="title">Contact</h2>
            </div>
            <FormField label="Phone" name="phone" reference={register({
                required: "Required",
                pattern: {
                    value: /^[0-9]{10}$/i,
                    message: "invalid South African phone number"
                }
            })} type="tel" errors={errors.phone} defaultValue={state.data.phone}/>
            <FormField label="Email" name="email" reference={register({
                required: "Required",
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                }
            })} type="email" errors={errors.email}
                       defaultValue={state.data.email}
            />

            <div className="col-md-12">
                <NextButton onNext={checkErrors}/>
                <PreviousButton onPrevious={() => {
                    GAEvent('Booking', 'Clicked Previous On Contact', "Navigation");
                    previous()
                }}/>
            </div>
        </div>
    );
};

export default Contact;
