import React from 'react';
import './App.css';
import {Router} from 'react-router-dom';
import Routes from './routes/routes';
import historyService from './services/history.service';
import {PreLoader} from "./shared/Preloader";
import {Navigation} from "./shared/Navigation";
import {Footer} from "./shared/Footer";
import {StateMachineProvider, createStore} from "little-state-machine";
import {initGA} from "./services/google-analytics.service";
import {createClient, Provider} from 'urql';

createStore({
    data: {}
});
const client = createClient({
    url: 'https://api-eu-central-1.graphcms.com/v2/ckdu4mw0359fw01xe2c2w8jcs/master',
});

class App extends React.Component {

    componentDidMount() {
        initGA();
    }

    render() {
        return (
            <>
                <Provider value={client}>
                    <StateMachineProvider>
                        <PreLoader/>
                        <Navigation/>
                        <Router history={historyService}>
                            <Routes/>
                        </Router>
                        <Footer/>
                    </StateMachineProvider>
                </Provider>
            </>
        );
    }
}

export default App;
