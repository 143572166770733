import React, {useEffect} from "react";
import {Hero} from "../../shared/Hero";
import {Main} from "../../shared/Main";
import {ServicesSection} from "./ServicesSection";
import {AboutSection} from "./AboutSection";
import {GAPageView} from "../../services/google-analytics.service";
import {usePagesQuery} from "../../generated/graphql";

export default function About() {
    const [{data, fetching}] = usePagesQuery({
        variables: {"Page": "About"}
    });

    useEffect(() => {
        GAPageView("About");
    }, []);
    return (
        <>
            <Hero imageUrl={!fetching ? data.pages[0].backgroundImage.url : ''} page={'about'} title={!fetching ? data.pages[0].displayName : ''}
                  subtitle={!fetching ? data.pages[0].description : ''}/>
            <Main sections={
                <>
                    <AboutSection/>
                    <div className="line col-md-8 col-md-offset-2 top_120 bottom_120"/>
                    <ServicesSection/>
                </>
            }/>
        </>
    );
}
