import React, {useEffect} from "react";

import FormField from "../FormComponents/FormField";
import Selector from "../FormComponents/Selector";
import {NextButton} from "../FormComponents/NextButton";
import {PreviousButton} from "../FormComponents/PreviousButton";
import TextArea from "../FormComponents/TextArea";
import {GAEvent, GAModalView} from "../../../../services/google-analytics.service";

const services = [
    [1, "Photography"],
    [2, "Videography"],
    [3, "both"],
];

const BookingDetails = ({state, action, register, errors, navigation, getValues, trigger}) => {
    const {previous, next} = navigation;

    useEffect(() => {
        GAModalView("BookingForm-BookingDetails");
    }, []);

    const checkErrors = async () => {
        const result = await trigger(['service', 'dateTime', 'additionalInformation']);
        if (result) {
            GAEvent('Booking', 'Clicked Next On Booking Details', "Navigation")
            action(getValues())
            next()
        }
    };

    return (
        <div className="booking-form">
            <div className="page-title">
                <h2 className="title">Booking details</h2>
            </div>
            <Selector items={services} label="Service" name="service" reference={register({required: "Required"})}
                      errors={errors.service}
                      defaultValue={state.data.service}/>
            <FormField
                label=""
                name="dateTime"
                reference={register({required: "Required"})}
                type="datetime-local"
                errors={errors.dateTime}
                defaultValue={state.data.dateTime}
            />
            <TextArea label="Any additional information we need to know?" name="additionalInformation"
                      reference={register} errors={errors.additionalInformation}
                      defaultValue={state.data.additionalInformation}
            />
            <div className="col-md-12">
                <NextButton onNext={checkErrors}/>
                <PreviousButton onPrevious={() => {
                    GAEvent('Booking', 'Clicked Previous On Booking Details', "Navigation");
                    previous()
                }}/>
            </div>
        </div>
    );
};

export default BookingDetails;
