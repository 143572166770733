import React from "react";
import {StatusCodes} from "../services/contact.service";
import $ from 'jquery';

export function Alert({status, message}) {
    switch (status) {
        case StatusCodes.SUCCESS:
            return (

                <div className="alert alert-success alert-dismissible" role="alert">
                    {message}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                            onClick={() => {
                                $('.alert').remove();
                            }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        default:
            return (

                <div className="alert alert-danger alert-dismissible" role="alert">
                    {message}
                    <button type="button" className="close" data-dismiss="alert" onClick={() => {
                        $('.alert').remove();
                    }}
                            aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
    }
}
