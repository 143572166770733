import React, {useEffect} from 'react'
import {Hero} from "../../shared/Hero";
import {Main} from "../../shared/Main";
import {BookingSection} from "./BookingSection";
import {GAPageView} from "../../services/google-analytics.service";
import {usePagesQuery} from "../../generated/graphql";

export default function Booking() {
    const [{data, fetching}] = usePagesQuery({
        variables: {"Page": "Booking"}
    });

    useEffect(() => {
        GAPageView("Booking");
    }, []);
    return (
        <>
            <Hero imageUrl={!fetching ? data.pages[0].backgroundImage.url : ''} page={'booking'} title={!fetching ? data.pages[0].displayName : ''}
                  subtitle={!fetching ? data.pages[0].description : ''}/>
            <Main sections={
                <BookingSection/>
            }/>
        </>
    );
}

