import React from "react";

export function Hero({ title, subtitle, page, imageUrl }) {
  return (
    <section
      style={{
        backgroundImage: `url('${imageUrl}')`,
      }}
      className={"hero " + page + " col-md-12"}
    >
      <div className="hero-inner">
        <h1>{title}</h1>
        <p style={{ whiteSpace: "pre-line" }}>{subtitle}</p>
        <div id="line-scroll" style={{ display: "block" }}>
          <div className="line-scroll" id="lsl">
            <span className="line active" />
          </div>
        </div>
      </div>
    </section>
  );
}
