import React from 'react';
import {Switch} from 'react-router';
import Route from './RouteWrapper';
import Home from "../pages/Home/Home";
import About from '../pages/About/About';
import Contact from "../pages/Contact/Contact";
import Booking from "../pages/Booking/Booking"

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/booking" component={Booking}/>
            <Route component={Home}/>
        </Switch>
    );
}
