import React from "react";

const FormField = ({label, children, errors, reference, ...otherProps}) => (
    <div className="col-md-6">
        <input className="form-inp requie" ref={reference} placeholder={label} {...otherProps}/>
        <span style={{color: 'red'}}>{errors && errors.message}</span>
    </div>
);

export default FormField;
