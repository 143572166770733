import React, {useEffect} from 'react'
import {GAEvent, GAPageView} from "../../../../services/google-analytics.service";
import {HomeButton} from "../../../../shared/HomeButton";
import {useStateMachine} from "little-state-machine";
import {resetState} from "../resetState";

export default function Cancel() {
    const {state, action} = useStateMachine(resetState);

    useEffect(() => {
        GAPageView("Booking-Cancel");
        GAEvent('Payment', 'Cancel Payment', 'Payment Cancellation');
        action({});
    }, []);

    return (
        <div>
            <div className="page-title">
                <h2 className="title">You can always pay later</h2>
            </div>
            <div style={{float: 'left'}}>
                <HomeButton title={"Go Home"}/>
            </div>
        </div>
    );
}
