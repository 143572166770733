import React from "react";

export class AboutSection extends React.Component {
    render() {
        return (
            <>
                <section className="about col-md-12 bottom_60">
                    <div className="col-md-5 image">
                        <img src="img/about.jpg" alt=""/>
                    </div>
                    <div className="col-md-6 col-md-offset-1 about-text">
                        <div className="page-title bottom_30">
                            <span>ABOUT US</span>
                            <h2 className="title">We are working on photography, videography and graphic design.</h2>
                        </div>

                        <p>
                            <b>Acxd Visuals</b> is an <b>Amazing</b> brand that <b>Captivates</b> its clientele
                            with <b>eXtraordinary</b> art, that is very <b>Debonair</b> to eye and the mind.
                            <br/><br/><b>Acxd Visuals</b> lens is focused on Photography, Videography and Graphic
                            Designing. The flashlight at <b>Acxd Visuals</b> illuminates the most unique digital art for
                            you. Art that steals ones breath, captured in its most authentic state.
                            <br/><br/>The art at <b>Acxd Visuals</b> is distinct because <b>ORDINARY IS NOT OUR
                            VOCABULARY</b> and authenticity best defines us. <br/><br/><b>FINE CRAFT BY YOU CAPTURED BY
                            ACXD VISUALS</b>
                        </p>
                    </div>
                </section>
            </>
        );
    }
}
