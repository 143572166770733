import React, {useEffect} from "react";
import {Hero} from "../../shared/Hero";
import {Main} from "../../shared/Main";
import Portfolio from "./Portfolio/Portfolio";
import {GAPageView} from "../../services/google-analytics.service";
import {usePagesQuery} from "../../generated/graphql";

export default function Home() {
    const [{data, fetching}] = usePagesQuery({
        variables: {"Page": "Home"}
    });

    useEffect(() => {
        GAPageView("Home");
    }, [])

    return (<>
        <Hero imageUrl={!fetching ? data.pages[0].backgroundImage.url : ''} page={''} title={!fetching ? data.pages[0].displayName : ''}
                  subtitle={!fetching ? data.pages[0].description : ''}/>
                          <Main sections={<Portfolio />}/></>)
}
