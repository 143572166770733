import React, {useEffect} from "react";

import FormField from "../FormComponents/FormField";
import Selector from "../FormComponents/Selector";
import {NextButton} from "../FormComponents/NextButton";
import {PreviousButton} from "../FormComponents/PreviousButton";
import {GAEvent, GAModalView} from "../../../../services/google-analytics.service";

const provinces = [
    [1, "Gauteng"],
    [2, "Mpumalanga"],
    [3, "Free State"],
    [4, "Northern Cape"],
    [5, "Limpopo"],
    [6, "North West"],
    [7, "Kwazulu Natal"],
    [8, "Eastern Cape"],
    [9, "Western Cape"]
];


const Address = ({state, action, register, errors, navigation, getValues, trigger}) => {
    const {previous, next} = navigation;

    useEffect(() => {
        GAModalView("BookingForm-Address");
    }, []);
    // const {firstName, lastName, nickName} = getValues();
    const checkErrors = async () => {
        const result = await trigger(['address', 'city', 'province', 'code']);
        if (result) {
            GAEvent('Booking', 'Clicked Next On Address', "Navigation")
            action(getValues())
            next()
        }
    };

    return (
        <div className="booking-form">
            <div className="page-title">
                <h2 className="title">Address</h2>
            </div>
            <FormField
                label="Address"
                name="address"
                reference={register({required: "Required"})}
                type="text"
                defaultValue={state.data.address}
                errors={errors.address}
            />
            <FormField label="City" name="city" reference={register({required: "Required"})} type="text"
                       errors={errors.city}
                       defaultValue={state.data.city}
            />
            <Selector items={provinces} label="Province" name="province" reference={register({required: "Required"})}
                      errors={errors.province}
                      defaultValue={state.data.province}
            />
            <FormField label="Postal Code" name="code" reference={register} type="number" errors={errors.code}
                       defaultValue={state.data.code}
            />
            <div className="col-md-12">
                <NextButton onNext={checkErrors}/>
                <PreviousButton onPrevious={() => {
                    GAEvent('Booking', 'Clicked Previous On Address', "Navigation");
                    previous()
                }}/>
            </div>
        </div>
    );
};

export default Address;
