import React from "react";
import {businessDetailsService} from "../services/business-details.service";
import {GAEvent} from "../services/google-analytics.service";

export function PaymentButton({title, firstName, lastName, email, phone, service, buttonStyling}) {
    const bookingData = {
        merchant_id: businessDetailsService.bookingDetails.merchantId,
        merchant_key: businessDetailsService.bookingDetails.merchantKey,
        return_url: window.location.origin + "/booking/success",
        cancel_url: window.location.origin + "/booking/cancel",
        name_first: firstName,
        name_last: lastName,
        email_address: email,
        cell_number: phone,
        item_name: service ? service + " Deposit" : "Deposit",
        amount: businessDetailsService.bookingDetails.fee,
        email_confirmation: businessDetailsService.bookingDetails.emailConfirmation,
        confirmation_address: businessDetailsService.bookingDetails.confirmationAddress,
    };

    return (
        <form action="https://www.payfast.co.za/eng/process" method="post">
            <input type="hidden" name="merchant_id" value={bookingData.merchant_id}/>
            <input type="hidden" name="merchant_key" value={bookingData.merchant_key}/>
            <input type="hidden" name="amount" value={bookingData.amount}/>
            <input type="hidden" name="return_url" value={bookingData.return_url}/>
            <input type="hidden" name="cancel_url" value={bookingData.cancel_url}/>
            {bookingData.name_first ? <input type="hidden" name="name_first" value={bookingData.name_first}/> : ""}
            {bookingData.name_last ? <input type="hidden" name="name_last" value={bookingData.name_last}/> : ""}
            {bookingData.email_address ?
                <input type="hidden" name="email_address" value={bookingData.email_address}/> : ""}
            {bookingData.cell_number ? <input type="hidden" name="cell_number" value={bookingData.cell_number}/> : ""}
            <input type="hidden" name="item_name" value={bookingData.item_name}/>
            <input type="hidden" name="email_confirmation" value={bookingData.email_confirmation}/>
            <input type="hidden" name="confirmation_address" value={bookingData.email_address}/>
            <button className="stepperbtn stepperbtn-right" type="submit"
                    style={buttonStyling ? buttonStyling : {}} onClick={() => {
                GAEvent('Payment', 'Deposit Payment Initiation', bookingData.item_name);
            }}>{title}</button>
        </form>);
}
