import React, {useEffect, useState} from "react";
import {
    StatusCodes,
    submitBookingForm
} from "../../../../services/contact.service";
import {Alert} from "../../../../shared/Alert";
import {PreLoader} from "../../../../shared/Preloader";
import {GAEvent, GAModalView} from "../../../../services/google-analytics.service";

const Review = ({state, handleSubmit, reset, navigation}) => {
    const [alert, showAlert] = useState(null);
    const [loading, showLoading] = useState(null);

    function handleResponse(response) {
        response.then(response => {
            if (StatusCodes.SUCCESS === response.status) {
                GAEvent('Booking', 'Booking Form Submission Success', 'Success Submission');
                showLoading(null)
                reset();
                go("submit");
            } else {
                GAEvent('Booking', 'Booking Form Submission Error', response.message);
                showAlert(() => {
                    return (
                        <Alert status={StatusCodes.ERROR} message={response.message}/>
                    );
                });
                showLoading(null)
            }
        }).catch(error => {
            GAEvent('Booking', 'Booking Form Submission Error', error.message);
            showAlert(() => {
                return (
                    <Alert status={StatusCodes.ERROR} message={error.message}/>
                );
            });
            showLoading(null)
        });
    }

    const submit = () => {
        showLoading(<PreLoader/>)
        GAEvent('Booking', 'Booking Form Submission', "Submit");
        const response = submitBookingForm(state.data);
        handleResponse(response);
    }

    const {go} = navigation;
    useEffect(() => {
        GAModalView("BookingForm-Review");
    }, []);

    return (
        <div className="booking-form">
            <div className="page-title">
                <h2 className="title">Review your data</h2>
            </div>
            <div className="row booking-info">
                <hr/>
                <div className="col-md-6">
                    <h4 style={{color: 'white'}}>Name</h4>
                    <hr/>
                    <div className="row">
                        <ul>
                            <li>First Name: <span>{`${state.data.firstName}`}</span></li>
                            <li>Last Name: <span>{`${state.data.lastName}`}</span></li>
                            <li>Nick Name: <span>{`${state.data.nickName}`}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <button className="stepperbtn stepperbtn-left" onClick={() => go("names")}>Edit</button>
                </div>
            </div>
            <div className="row booking-info">
                <hr/>
                <div className="col-md-6">
                    <h4 style={{color: 'white'}}>Address</h4>
                    <hr/>
                    <div className="row">
                        <ul>
                            <li>Address: <span>{`${state.data.address}`}</span></li>
                            <li>City: <span>{`${state.data.city}`}</span></li>
                            <li>Province: <span>{`${state.data.province}`}</span></li>
                            <li>Code: <span>{`${state.data.code}`}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 booking-info">
                    <button className="stepperbtn stepperbtn-left" onClick={() => go("address")}>Edit</button>
                </div>
            </div>
            <div className="row">
                <hr/>
                <div className="col-md-6 booking-info">
                    <h4 style={{color: 'white'}}>Contact</h4>
                    <hr/>
                    <div className="row">
                        <ul>
                            <li>Phone: <span>{`${state.data.phone}`}</span></li>
                            <li>E-mail: <span>{`${state.data.email}`}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <button className="stepperbtn stepperbtn-left" onClick={() => go("contact")}>Edit</button>
                </div>
            </div>
            <div className="row">
                <hr/>
                <div className="col-md-6 booking-info">
                    <h4 style={{color: 'white'}}>Booking Details</h4>
                    <hr/>
                    <div className="row">
                        <ul>
                            <li>Service: <span>{`${state.data.service}`}</span></li>
                            <li>Date and Time: <span>{`${
                                new Date(state.data.dateTime)
                            }`}</span></li>
                            <li>Additional Information: <span>{`${state.data.additionalInformation}`}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <button className="stepperbtn stepperbtn-left" onClick={() => go("booking-details")}>Edit
                    </button>
                </div>
            </div>
            {loading}
            {alert}
            <div style={{float: 'right'}}>
                <button className="stepperbtn stepperbtn-right" onClick={handleSubmit(submit)} type={"submit"}>Submit
                </button>
            </div>
        </div>
    );
};

export default Review;
