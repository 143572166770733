import React, {useEffect, useState} from "react";
import {PortfolioItem} from "./PortfolioItem";
import {GAEvent} from "../../../services/google-analytics.service";
import * as $ from 'jquery';
import * as Isotope from 'isotope-layout';
import * as imagesLoaded from 'imagesloaded';
import {usePortfolioItemsQuery} from "../../../generated/graphql";

function Portfolio() {
    const [{data, fetching}] = usePortfolioItemsQuery();

    const [isotope, setIsotope] = React.useState(null);
    const [filterKey, setFilterKey] = React.useState("*");
    const [hasVideoItems, setHasVideoItems] = useState(false);

    useEffect(() => {
        if (data) {
            setHasVideoItems(data.portfolioItems.find(p => p.category.includes('Videography')));
            imagesLoaded('.isotope_items').on('progress', function () {
                setIsotope(
                    new Isotope(".isotope_items", {
                        itemSelector: ".single_item",
                        layoutMode: "masonry",
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false,
                        }
                    }));
            });
        }
    }, [fetching, data]);

    useEffect(() => {
            if (isotope) {
                filterKey === "*"
                    ? isotope.arrange({filter: `*`})
                    : isotope.arrange({filter: `.${filterKey}`});
            }
        }, [isotope, filterKey]
    );

    function handleFilterClickEvent(e) {
        $(".portfolio-filter ul li").removeClass("select-cat");
        $(e.target).addClass("select-cat");
        $('html, body').animate({
            scrollTop: $(".isotope_items").offset().top - 30
        }, 500);
    }

    return !fetching && <section className="portfolio col-md-12">
        <div className="col-md-3">
            <div className="left-filter-section">
                <div className="page-title left">
                    <span>PORTFOLIO</span>
                    <h2 className="title">We design <br/> experiences</h2>
                </div>
                <div className="portfolio-filter">
                    <ul>
                        <li className="select-cat" data-filter="*" onClick={e => {
                            handleFilterClickEvent(e);
                            setFilterKey("*");
                            GAEvent('Filter', 'Filter All', "Filter")
                        }}
                        >All Works
                        </li>
                        {hasVideoItems ?
                            <>
                                <li data-filter=".Videography" onClick={e => {
                                    handleFilterClickEvent(e);
                                    setFilterKey("Videography");
                                    GAEvent('Filter', 'Filter Videography', "Filter")
                                }}
                                >Videography
                                </li>
                                <li data-filter=".Photography" onClick={e => {
                                    handleFilterClickEvent(e);
                                    setFilterKey("Photography");
                                    GAEvent('Filter', 'Filter Photography', "Filter")
                                }}
                                >Photography
                                </li>
                            </>
                            : ''
                        }
                    </ul>
                </div>
            </div>
        </div>

        <div className="col-md-9">
            <div className="isotope_items row">
                {data.portfolioItems.map(portfolioItem => (
                    <PortfolioItem
                        key={portfolioItem.id}
                        id={portfolioItem.id}
                        description={portfolioItem.description}
                        name={portfolioItem.name}
                        category={portfolioItem.category}
                        image={portfolioItem.image}
                        videoLink={portfolioItem.videoLink}
                    />
                ))}
            </div>
        </div>
    </section>
}

export default Portfolio;
