import React from "react";

export class PreLoader extends React.Component {
    render() {
        return (
            <div id="preloader">
                <div className="spinner">
                    <div className="uil-ripple-css" style={{transform: 'scale(0.29)'}}>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>);
    }
}

