import axios from "axios";
import {GAException} from "./google-analytics.service";

export class ContactFormModel {
    name;
    email;
    message;
}

export const StatusCodes = {
    SUCCESS: 'Success',
    ERROR: 'Error'
}

export class ClientResponseModel {
    _status;
    _message;
    _data;

    constructor(s, m, d) {
        this._status = s;
        this._message = m;
        this._data = d;
    }

    get status() {
        return this._status;
    }

    get message() {
        return this._message;
    }

    get data() {
        return this._data;
    }
}

export class BookingFormModel {
    firstName;
    lastName;
    nickName;
    address;
    city;
    province;
    code;
    email;
    phone;
    service;
    dateTime;
    additionalInformation;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.nickName = '';
        this.address = '';
        this.city = '';
        this.province = '';
        this.code = '';
        this.email = '';
        this.phone = '';
        this.service = '';
        this.dateTime = new Date();
        this.additionalInformation = '';
    }
}

const contactFormPortalId = '8271378';
const contactFormId = '97ecd7be-343c-4f14-bd16-c9bd0d79a502';
const contactFormURI = `https://api.hsforms.com/submissions/v3/integration/submit/${contactFormPortalId}/${contactFormId}`;

export function submitContactForm(contactFormData) {
    const body = createContactFormData(contactFormData.name, contactFormData.email, contactFormData.message);
    return axios.post(contactFormURI, body)
        .then(response => {
            if (response.status === 200) {
                return new ClientResponseModel(StatusCodes.SUCCESS, response.data.inlineMessage);
            } else {
                GAException(`Contact: ${response.toString()}`);
            }
        }).catch(error => {
            GAException(`Contact: ${error.toString()}`);
            return new ClientResponseModel(StatusCodes.ERROR, 'An error occurred, whilst sending your information. please try again later.');
        })
}

const bookingFormPortalId = '8271378';
const bookingFormId = 'e9e410ea-ff03-47f1-9b5e-b6161991edc7';
const bookingFormURI = `https://api.hsforms.com/submissions/v3/integration/submit/${bookingFormPortalId}/${bookingFormId}`;

export function submitBookingForm(bookingFormData) {
    const body = createBookingFormData(bookingFormData.firstName, bookingFormData.lastName,
        bookingFormData.nickName, bookingFormData.address, bookingFormData.city, bookingFormData.province,
        bookingFormData.code, bookingFormData.email, bookingFormData.phone, bookingFormData.service,
        bookingFormData.dateTime, bookingFormData.additionalInformation);

    return axios.post(bookingFormURI, body)
        .then(response => {
            if (response.status === 200) {
                return new ClientResponseModel(StatusCodes.SUCCESS, response.data.inlineMessage);
            } else {
                GAException(`Booking: ${response.toString()}`);
            }
        }).catch(error => {
            GAException(`Booking: ${error.toString()}`);
            return new ClientResponseModel(StatusCodes.ERROR, 'An error occurred, whilst sending your information. please try again later.');
        })
}

function createContactFormData(name, email, message) {
    return {
        submittedAt: Date.now(),
        fields: [
            {
                name: 'firstname',
                value: name
            },
            {
                name: 'email',
                value: email
            },
            {
                name: 'subject',
                value: ''
            },
            {
                name: 'message',
                value: message === null ? '' : message
            }],
        context: {
            pageUri: 'https://acxdvisuals.co.za/',
            pageName: 'Contact Page'
        }
    };
}

function createBookingFormData(firstName, lastName, nickName, address, city, province,
                               code, email, phone, service, dateTime, additionalInformation) {
    return {
        submittedAt: Date.now(),
        fields: [
            {
                name: 'firstname',
                value: firstName
            },
            {
                name: 'lastname',
                value: lastName
            },
            {
                name: 'company',
                value: nickName
            },
            {
                name: 'address',
                value: address
            },
            {
                name: 'city',
                value: city
            },
            {
                name: 'state',
                value: province
            },
            {
                name: 'zip',
                value: code
            },
            {
                name: 'email',
                value: email
            },
            {
                name: 'phone',
                value: phone
            },
            {
                name: 'datetime',
                value: dateTime
            },
            {
                name: 'service',
                value: service
            },
            {
                name: 'message',
                value: additionalInformation
            }],
        context: {
            pageUri: 'https://www.acxdvisuals.co.za',
            pageName: 'Booking Page'
        }
    };
}
