import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize('UA-98319116-3');
}

export const GAPageView = (page) => {
    ReactGA.pageview(page);
}

export const GAModalView = (modal) => {
    ReactGA.modalview(modal);
};

export const GAEvent = (categoryName, eventName, labelName) => {
    ReactGA.event({
        category: categoryName,
        action: eventName,
        label: labelName,
        value: 10,
        nonInteraction: false
    });
}


export const GATiming = (categoryName, variableName, valueNum) => {
    ReactGA.timing({
        category: categoryName,
        variable: variableName,
        value: valueNum
    });
};

export const GAException = (detail) => {
    ReactGA.exception({description: detail});
};
