import {useParams} from "react-router";
import Cancel from "./Stepper/FormSections/Cancel";
import Success from "./Stepper/FormSections/Success";
import React from "react";

const BookingAction = () => {
    let {paramId} = useParams();

    switch (paramId) {
        case "cancel":
            return <Cancel/>;
        case "success":
            return <Success/>;
        default:
            return null;
    }
};

export default BookingAction;
