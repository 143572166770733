export const businessDetailsService = {
    socialMedia: {
        instagram: 'https://www.instagram.com/acxd_visuals',
        facebook: 'https://www.facebook.com/NqobizithaKhupe01',
        twitter: 'https://twitter.com/AcxdVisuals'
    },
    contactDetails: {
        phone: '+27 60 489 8693',
        callLink: 'tel:060-489-8693',
        email: 'acxd.visuals@mail.com',
        emailLink: 'mailto:acxd.visuals@mail.com',
        address: 'Centurion, Gauteng'
    },
    bookingDetails: {
        fee: "250.00",
        merchantId: "16418611",
        merchantKey: "zmo1qormsdxdb",
        emailConfirmation: "1",
        confirmationAddress: "acxd.visuals@mail.com",
    }
}
