import React from "react";
import {useStep} from "react-hooks-helper";
import {useForm} from "react-hook-form";
import {useStateMachine} from "little-state-machine";

import Names from "./FormSections/Names";
import Address from "./FormSections/Address";
import Contact from "./FormSections/Contact";
import Review from "./FormSections/Review";
import Submit from "./FormSections/Submit";
import BookingDetails from "./FormSections/BookingDetails";
import updateAction from "./updateAction";
import {useParams} from "react-router";
import Cancel from "./FormSections/Cancel";
import Success from "./FormSections/Success";

const steps = [
    {id: "names"},
    {id: "address"},
    {id: "contact"},
    {id: "booking-details"},
    {id: "review"},
    {id: "submit"}
];

const Stepper = ({images}) => {
    let {paramId} = useParams();

    console.log('ParamId', paramId);

    const {handleSubmit, register, reset, errors, getValues, trigger} = useForm();
    const {step, navigation} = useStep({initialStep: 0, steps});
    const {id} = paramId ? paramId : step;

    const {action, state} = useStateMachine(updateAction);

    const commonProps = {state, action, register, errors, navigation, getValues, trigger};
    const submitProps = {state, action, handleSubmit, reset, navigation, getValues};

    switch (id) {
        case "names":
            return <Names {...commonProps} />;
        case "address":
            return <Address {...commonProps} />;
        case "contact":
            return <Contact {...commonProps} />;
        case "booking-details":
            return <BookingDetails {...commonProps} />;
        case "review":
            return <Review {...submitProps} />;
        case "submit":
            return <Submit {...submitProps}/>;
        case "cancel":
            return <Cancel/>;
        case "success":
            return <Success/>;
        default:
            return null;
    }
};

export default Stepper;
